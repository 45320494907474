import React, { Fragment } from 'react';
import Hero from '../components/projects-out/hero';
import Projects from '../components/projects-out/projects';
import Ubication from '../components/ubication';
import Contact from '../components/contact';

export default ()=> {

  return(
    <Fragment>
      <Hero />
      <Projects />
      <Ubication />
      <Contact />
    </Fragment>
  )
}